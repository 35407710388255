
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

// Components

@Component({
  components: {},
})
export default class BreadCrumbComponent extends Vue {
  @Prop() breadCrumbData: BreadCrumbDetail[];
  @Prop({ default: false }) newsSite: boolean;
  @Prop({ default: false }) singleBlogPost: boolean;
  @Prop({ default: "" }) prefix: string;
}
